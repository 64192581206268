<template>
  <div class="contact-form-section section-space--ptb_30" id="contact">
    <Navbar class="is-sticky"/>
    <div class="container radix-nav">
      <div class="centered pb-5">
        <div class="text-center section-space--mb_30 mt-3">
          <img
            :src='"../assets/img/logo/coloured-logo-dark-text.png"'
            alt="logo"
          />
        </div>
        <div class="section-title text-center section-space--mb_60">
          <h2 class="font-weight--bold mb-15 wow move-up">{{ title }}</h2>
          <span class="section-text_left wow move-up">{{ subtitle }}</span>
        </div>
        <div
          v-if="!isSignUpSuccessful"
          class="contact-from-wrapper wow move-up"
        >
          <div id="contact-form">
            <div class="contact-page-form">
              <p
                class="error-msg"
                v-if="showValidationError && !validateForm()"
              >
                Please fill in all the fields.
              </p>
              <div class="contact-inner">
                <input
                  :class="
                    showValidationError && firstName === '' ? 'error' : ''
                  "
                  name="first_name"
                  v-model="firstName"
                  type="text"
                  placeholder="First Name *"
                />
              </div>
              <div class="contact-inner">
                <input
                  :class="showValidationError && lastName === '' ? 'error' : ''"
                  name="last_name"
                  v-model="lastName"
                  type="text"
                  placeholder="Last Name *"
                />
              </div>
              <div class="contact-inner">
                <input
                  :class="
                    showValidationError && username === ''
                      ? 'error'
                      : '' && !isValidUsername
                      ? 'error'
                      : ''
                  "
                  name="con_name"
                  v-model="username"
                  @input="validateUsername"
                  type="text"
                  placeholder="Username *"
                />
                <p class="pl-3 error-msg" v-if="usernameExists">
                  This username already exists. Please use a different username.
                </p>
                <p
                  class="pl-3 error-msg"
                  v-if="
                    showUsernameError && !isValidUsername && username !== ''
                  "
                >
                  Characters allowed are letters (A-Z), numbers (0-9), periods
                  (.), and underscores (_).
                </p>
              </div>
              <div class="contact-inner">
                <b-input-group>
                  <b-form-input
                    class="bstrap-input"
                    :class="
                      showValidationError && !validatePassword() ? 'error' : ''
                    "
                    name="con_subject"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="Password *"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      class="toggle-btn"
                      variant="outline-secondary"
                      @click="toggleShowPassword"
                    >
                      <i
                        :class="
                          showPassword
                            ? 'fa fa-eye-slash eye-icon'
                            : 'fa fa-eye eye-icon'
                        "
                      ></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <p
                  class="pl-3 error-msg"
                  v-if="showValidationError && !validatePassword()"
                >
                  Password has to be minimum 8 characters.
                </p>
              </div>
              <div class="contact-inner">
                <input
                  :class="
                    showValidationError && !validateEmail() ? 'error' : ''
                  "
                  name="con_email"
                  v-model="email"
                  type="email"
                  placeholder="Company Email *"
                />
                <p
                  class="pl-3 error-msg"
                  v-if="showValidationError && !validateEmail()"
                >
                  Please provide a valid company email.
                </p>
                <p class="pl-3 error-msg" v-if="emailExists">
                  This email already exists. Please use a different email
                  address.
                </p>
              </div>
              <div class="contact-inner">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-form-select v-model="dialCode">
                      <b-form-select-option
                        v-for="code in dialCodes"
                        :value="code.dial_code"
                        :key="code.code"
                        >{{ code.code }}
                        {{ code.dial_code }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-input-group-prepend>
                  <b-form-input
                    class="bstrap-input"
                    :class="showValidationError && mobile === '' ? 'error' : ''"
                    name="con_email"
                    v-model="mobile"
                    type="number"
                    placeholder="Mobile Number *"
                  ></b-form-input>
                </b-input-group>
                <p class="pl-3 error-msg" v-if="phoneExists">
                  This number already exists. Please use a different mobile
                  number.
                </p>
              </div>
              <div class="contact-submit-btn text-center">
                <button
                  class="ht-btn ht-btn-md mr-2 signup-btn"
                  @click="handleSignUp"
                  :disabled="!isValidUsername"
                >
                  Sign Up
                </button>
                <b-spinner class="pt-3" v-if="isCreatingUser"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center">
          <button class="ht-btn ht-btn-md" @click="goHome">Back to Home</button>
          <button class="ht-btn ht-btn-md ml-3 goto-app-btn">
            <a
              :href="`https://${isDevMode ? 'dev' : ''}app.radixplore.com`"
              target="_blank"
              >Go to RadiXplore app</a
            >
          </button>
        </div>
      </div>
    </div>
    <FooterMain class="radix-nav" FooterStyle="bg_drak-footer"/>
  </div>
</template>
<script src="https://www.google.com/recaptcha/api.js?render=6LfY-QkpAAAAALm1TMAfM0UMv1JBCimPYBCKw__I"></script>
<script>
import Navbar from '../components/Navbar';
import FooterMain from '../components/FooterMain';
import ENV from "../envConfig";

const AWS = require("aws-sdk");
const emailProviders = require("../data/freeEmailProviders.json");
const callingCodes = require("../data/callingCodes.json");
const signupconfig = {
  headers: {
    Authorization: "Bearer " + ENV.VUE_APP_RX_API_KEY,
  },
};

AWS.config.update({
  region: ENV.VUE_APP_AWS_REGION,
  accessKeyId: ENV.VUE_APP_ACCESS_KEY_ID,
  secretAccessKey: ENV.VUE_APP_SECRET_ACCESS_KEY,
});

export default {
  name: "SignUp",
  components: {
    Navbar,
    FooterMain
  },
  computed: {
    dialCodes() {
      return callingCodes;
    },
    isValidUsername() {
      const regex = /^[a-zA-Z0-9_.]+$/;
      return regex.test(this.username);
    },
  },
  data() {
    return {
      title: "Create Account",
      subtitle: "Let’s get you started with RadiXplore.",
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      dialCode: "+61",
      mobile: "",
      showValidationError: false,
      showUsernameError: false,
      showPassword: false,
      isSignUpSuccessful: false,
      isCreatingUser: false,
      usernameExists: false,
      emailExists: false,
      phoneExists: false,
      isDevMode: false,
      recaptchaResponse: null,
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    validateForm() {
      if (
        this.username === "" ||
        this.password === "" ||
        this.email === "" ||
        this.mobile === ""
      ) {
        this.showValidationError = true;
        return false;
      }

      return true;
    },
    validatePassword() {
      if (this.password === "" || this.password.length < 8) {
        this.showValidationError = true;
        return false;
      }

      return true;
    },
    validateEmail() {
      if (this.email === "") {
        return false;
      }
      const domain = this.email.split("@")[1];
      if (emailProviders.includes(domain)) {
        this.showValidationError = true;
        return false;
      }
      return true;
    },
    validateUsername() {
      this.showUsernameError = !this.isValidUsername;
    },
    async checkUserExistence(endpoint) {
      try {
        const response = await this.$axios.get(endpoint, signupconfig);
        if (response.data.code === 200) {
          return true;
        } else if (response.data.code === 404) {
          return false;
        }
      } catch (error) {
        console.error("Error checking user existence:", error);
        throw error;
      }
    },
    async userAttributesExist() {
      const usernameEndpoint = `${ENV.VUE_APP_RX_API_URL}/users/${this.username}`;
      const emailEndpoint = `${ENV.VUE_APP_RX_API_URL}/users/verify/${this.email}`;
      const phoneEndpoint = `${
        ENV.VUE_APP_RX_API_URL
      }/users/verify/${this.dialCode.concat(this.mobile)}`;

      try {
        this.usernameExists = await this.checkUserExistence(usernameEndpoint);
        this.emailExists = await this.checkUserExistence(emailEndpoint);
        this.phoneExists = await this.checkUserExistence(phoneEndpoint);

        return this.usernameExists || this.emailExists || this.phoneExists;
      } catch (error) {
        console.error("Error checking user attributes existence:", error);
        throw error;
      }
    },
    formatDateToYYYYMMDD(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // async createUserSubscription(username) {
    //   console.log("creating subscription");
    //   let date = new Date();
    //   date = this.formatDateToYYYYMMDD(date);
    //   this.$axios
    //     .post(
    //       `${ENV.VUE_APP_RX_API_URL}/users/subscription`,
    //       {
    //         username,
    //         startDate: date,
    //         isActive: true,
    //         licenseType: "free",
    //         searchCount: 10,
    //         tableExtractionCount: 2,
    //         radixEyeCount: 10,
    //         radixFinanceCount: 10,
    //         radixGPTCount: 10,
    //         assayFinderCount: 10
    //       },
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + ENV.VUE_APP_RX_API_KEY,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       return res.data.data[0];
    //     })
    //     .catch((err) => {
    //       console.error("Failed to create new user.", err);
    //       return {};
    //     });
    // },
    // async createNewUser(username) {
    //   const subscriptionDetails = await this.createUserSubscription(username);
    //   this.$axios
    //     .post(
    //       `${ENV.VUE_APP_RX_API_URL}/users`,
    //       {
    //         username: username,
    //         permissions: [
    //           "licence.free",
    //           "module.radixsearch",
    //           "module.radixsuggest",
    //           "module.radixeye",
    //           "module.radixextract",
    //           "module.radixgpt",
    //         ],
    //         subscriptionDetails,
    //       },
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + ENV.VUE_APP_RX_API_KEY,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       console.log("New user created.");
    //     })
    //     .catch((err) => console.error("Failed to create new user.", err));
    // },
    async getReCaptchaResponse() {
      try {
        this.recaptchaResponse = await grecaptcha.execute(
          "6LfY-QkpAAAAALm1TMAfM0UMv1JBCimPYBCKw__I",
          { action: "signup" }
        );
      } catch (error) {
        console.error("Failed to get reCAPTCHA response:", error);
      }
    },
    async handleSignUp() {
      if (
        !this.validateForm() ||
        !this.validatePassword() ||
        !this.validateEmail()
      ) {
        return;
      }
      await this.getReCaptchaResponse();
      try {
        const userAttributesExist = await this.userAttributesExist();
        if (userAttributesExist) {
          if (
            await this.checkUserExistence(
              `${ENV.VUE_APP_RX_API_URL}/users/${this.username}`
            )
          ) {
            this.title = "Failed to Create Account";
            this.subtitle =
              "Username already in use. Please choose a different one.";
          } else if (
            await this.checkUserExistence(
              `${ENV.VUE_APP_RX_API_URL}/users/verify/${this.email}`
            )
          ) {
            this.title = "Failed to Create Account";
            this.subtitle =
              "Email already in use. Please use a different email.";
          } else if (
            await this.checkUserExistence(
              `${
                ENV.VUE_APP_RX_API_URL
              }/users/verify/${this.dialCode.concat(this.mobile)}`
            )
          ) {
            this.title = "Failed to Create Account";
            this.subtitle =
              "Phone number already in use. Please use a different phone number.";
          }
          console.log(
            "User with the given username, email, or phone number already exists. Cannot proceed with signup."
          );
          return;
        }
        const response = await this.$axios.get(
          `${ENV.VUE_APP_RX_API_URL}/reCaptcha/?recaptcha_response=${this.recaptchaResponse}`
        );
        if (response.data.data[0] === "Success") {
          const userRegistrationData = {
            username: this.username,
            email: this.email,
            firstname: this.firstName,
            lastname: this.lastName,
            phone_number: this.dialCode.concat(this.mobile),
            password: this.password,
            createdAt: new Date().toISOString(),
          };
          try {
            const signUpResponse = await this.$axios.post(
              `${ENV.VUE_APP_RX_API_URL}/users/signup`,
              userRegistrationData,
              signupconfig
            );
            if (signUpResponse.data.code === 200) {
              this.isCreatingUser = false;
              this.title = "Account Created";
              this.subtitle =
                "A verification code will be sent to the email address you provided. Please enter this code the first time you log in to Radixplore to verify your account.";
              this.isSignUpSuccessful = true;

              this.$gtag.event('user_signup', {
                event_category: 'Signup',
                username: this.username,
                email: this.email,
                firstname: this.firstName,
                lastname: this.lastName,
                phone_number: this.dialCode.concat(this.mobile),
              })
            } else {
              this.title = "Failed to Create Account";
              this.subtitle =
                "An error occurred while trying to create your account. Please try again.";
              console.error(
                "Failed to create user in the backend:",
                signUpResponse.data.message
              );
            }
          } catch (error) {
            console.error("Error creating user in the backend:", error);
            this.title = "Failed to Create Account";
            this.subtitle =
              "An error occurred during account creation. Please try again.";
          }
        } else {
          console.log("Recaptcha failed");
          this.title = "Failed to Create Account";
          this.subtitle = "reCAPTCHA verification failed";
        }
      } catch (error) {
        console.error("Error during signup:", error);
        this.title = "Failed to Create Account";
        this.subtitle = "An error occurred during signup. Please try again.";
      }
    },
  },
  mounted() {
    if (ENV.VUE_APP_NODE_ENV === "development") {
      this.isDevMode = true;
    }
  },
};
</script>

<style scoped>
.input-group input:focus {
  outline: none !important;
}
.centered {
  margin: 0 auto;
  width: 50%;
}

.error {
  border-color: salmon;
}

.error-msg {
  font-size: 0.8rem;
  color: salmon;
}

.signup-btn:disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 1000px) {
  .centered {
    width: 85%;
  }
}

img {
  height: 60px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bstrap-input:focus {
  background-color: #f8f8f8;
  border: 1px solid #eee;
  box-shadow: none;
}

.toggle-btn:hover {
  border: 1px solid #eee;
  background: #f8f8f8;
  height: 100%;
}
.toggle-btn {
  height: 100%;
  border: 1px solid #eee;
  background: #f8f8f8;
}

.eye-icon {
  color: #5b3c5b;
}

.contact-inner input,
.contact-inner select {
  color: #5b3c5b;
}

@media screen and (max-width: 515px) {
  .goto-app-btn {
    margin-top: 16px;
    margin-right: 12px;
  }
}
</style>
