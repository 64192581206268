<template>
    <div class="feature-list__one">
        <div class="row">
            <div v-for="(item, x) in items" :key="x" class="col-lg-6 wow move-up">
                <div class="grid-item animate">
                    <div class="ht-box-icon style-01">
                        <div class="icon-box-wrap">
                            <div class="image">
                                <img :src="item.icon" class="img-fluid" alt="aeroland-app-showcase-box-icon-image-01">
                            </div>
                            <div class="content">
                                <h6 class="heading">{{ item.heading }}</h6>
                                <div class="text">{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FeatureApp',
        data (){
            return {
                items: [
                    {
                        icon: require('../assets/img/icons/artificial-intelligence.png'), 
                        heading: 'Artificial Intelligence', 
                        text: 'RadiXplore is an AI platform designed to help you find and filter through millions of documents quickly and effectively.'
                    },
                    {
                        icon: require('../assets/img/icons/neural.png'), 
                        heading: 'Search With Context', 
                        text: 'It is trained to recognise geological terms and context, so you can rest assured it will give relevant results without wasting your time.'
                    },
                    {
                        icon: require('../assets/img/icons/file.png'), 
                        heading: 'All Document Types', 
                        text: 'RadiXplore caters for all unstructured data types including poor quality scanned handwritten documents.'
                    },
                    {
                        icon: require('../assets/img/icons/quick.png'), 
                        heading: 'Blazing Speed', 
                        text: 'We reduce the time needed to find relevant information from days to hours.'
                    },
                ]
            }
        }
    }
</script>
