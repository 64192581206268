<template>
  <div>
    <Navbar class="is-sticky"/>
    <div v-if="post" class="social-sharing is-sticky">
      Share on
      <b-button :href="facebookShareUrl" target="_blank" title="Facebook" class="social-button">
        <i class="fab fa-facebook-f" style="color: #3b5998;"></i>
      </b-button>
      <b-button :href="twitterShareUrl" target="_blank" title="Twitter" class="social-button">
        <i class="fab fa-twitter" style="color: #1DA1F2;"></i>
      </b-button>
      <b-button :href="linkedinShareUrl" target="_blank" title="LinkedIn" class="social-button">
        <i class="fab fa-linkedin-in" style="color: #0077B5;"></i>
      </b-button>
      <b-button :href="whatsappShareUrl" target="_blank" title="WhatsApp" class="social-button">
        <i class="fab fa-whatsapp" style="color: #25D366;"></i>
      </b-button>
      <b-button :href="emailShareUrl" target="_blank" title="Email" class="social-button">
        <i class="fas fa-envelope" style="color: #D44638;"></i>
      </b-button>
    </div>
    <div class="blog-post-section">
      <div class="blog-post">
        <h1 class="blog-post-title mb-3">{{ post.title }}</h1>
        <div class="image-container">
          <img :src="post.feature_image" :alt="post.title" class="blog-post-image" />
        </div>
        <div class="blog-post-content" v-html="post.html"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Navbar from '../components/Navbar';

export default {
  name: 'BlogPost',
  components: {
    Navbar
  },
  data() {
    return {
      post: null,
    };
  },
  computed: {
    facebookShareUrl() {
      const postUrl = encodeURIComponent(window.location.href);
      return `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`;
    },
    twitterShareUrl() {
      const postUrl = encodeURIComponent(window.location.href);
      const postTitle = encodeURIComponent(this.post.title);
      return `https://twitter.com/intent/tweet?url=${postUrl}&text=${postTitle}`;
    },
    linkedinShareUrl() {
      const postUrl = encodeURIComponent(window.location.href);
      const postTitle = encodeURIComponent(this.post.title);
      return `https://www.linkedin.com/shareArticle?url=${postUrl}&title=${postTitle}`;
    },
    whatsappShareUrl() {
      const postUrl = encodeURIComponent(window.location.href);
      return `whatsapp://send?text=${postUrl}`;
    },
    emailShareUrl() {
      const postUrl = encodeURIComponent(window.location.href);
      const postTitle = encodeURIComponent(this.post.title);
      return `mailto:?subject=${postTitle}&body=${postUrl}`;
    }
  },
  created() {
    this.fetchBlogPost();
  },
  methods: {
    async fetchBlogPost() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get(`https://blog.radixplore.com/ghost/api/v3/content/posts/slug/${slug}?key=35676c260a882c10ce47ef3411`);
        this.post = response.data.posts[0];
      } catch (error) {
        console.error('Error fetching blog post:', error);
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/variabls.scss';
.blog-post-section {
  padding: 8rem 0 3rem;
  @media #{$small-mobile}{
    padding: 9rem 0 3rem;
  }
}

.blog-post {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background: white;
}

.image-container {
  text-align: center;
}

.blog-post-image {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 60vh;
  object-fit: cover;
  border-radius: 10px;
}

.blog-post-title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.blog-post-content {
  margin-top: 36px;
  font-size: 16px;
  line-height: 1.6;
}

.blog-post-content img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 60vh;
  object-fit: cover;
}

.blog-post-content h1 {
  font-size: 32px;
  margin-bottom: 8px;
}

.blog-post-content h2 {
  font-size: 28px;
  margin-bottom: 8px;
}

.blog-post-content h3 {
  font-size: 24px;
  margin-bottom: 8px;
}

.blog-post-content h4 {
  font-size: 22px;
  margin-bottom: 8px;
}

.blog-post-content h5 {
  font-size: 20px;
  margin-bottom: 8px;
}

.blog-post-content h6 {
  font-size: 18px;
  margin-bottom: 8px;
}

.blog-post-content p {
  margin-bottom: 2rem;
}

.blog-post-content ul {
  list-style: unset;
  margin-bottom: 2rem;
  padding: revert;
}

.social-sharing {
  position: sticky;
  top: 8rem;
  float: right;
  margin-right: 20px;
  z-index: 1000;
  text-align: -webkit-center;
  text-align: -moz-center;
  @media #{$small-mobile}{
    float: none;
    margin-right: 0;
    top: 9rem;
    position: relative;
    z-index: inherit;
  }
}

.social-button {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  background-color: #d1d1d185;
  @media #{$small-mobile}{
    display: inline-block;
    margin-right: 4px;
  }
}

.social-button:hover {
  background-color: #c7c7c7ab;
}
</style>
