<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'app',
    mounted() {
        if (localStorage.getItem('user_id')) {
            this.$gtag.config({
                'user_id': localStorage.getItem('user_id')
            })
        } else {
            const user_id =  uuidv4();
            localStorage.setItem('user_id', user_id);

            this.$gtag.config({
                'user_id': user_id
            })
        }
    }
}
</script>

