<template>
  <div>
    <b-table class="plan-table" hover :items="plans" :fields="planFields">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
          <div class="price">A$0.00 forever</div>
          <!-- <button class="ht-btn ht-btn-default">Sign Up</button> -->
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
          <div class="price">A$5000 / user</div>
          <!-- <button class="ht-btn ht-btn-default">Get Plan</button> -->
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
          <div class="price">Get Quote</div>
          <!-- <button class="ht-btn ht-btn-default">Get Plan</button> -->
        </div>
      </template>
    </b-table>
    <br>
    <div class="group-label">Dataset</div>
    <b-table hover :items="dataset" :fields="tableFields" @row-clicked="handleRowClick">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
    </b-table>
    <!-- <report-year-range-modal/> -->
    <br>
    <div class="group-label">RadixSearch</div>
    <b-table hover :fields="tableFields" :items="radixSearch" @row-clicked="handleRowClick">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:cell(free)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(budget)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(enterprise)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>
    <br>
    <div class="group-label radixeye-label" @click="handleRadixEyeClick">RadixEye</div>
    <b-table class="radixEye-table" hover :fields="tableFields" :items="radixEye" @row-clicked="handleRowClick">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:cell(free)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(budget)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(enterprise)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>
    <br>
    <div class="group-label">RadixGPT</div>
    <b-table hover :fields="tableFields" :items="radixGpt" @row-clicked="handleRowClick">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:cell(free)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(budget)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(enterprise)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>
    <br>
    <div class="group-label">Results and Insights</div>
    <b-table hover :fields="tableFields" :items="results" @row-clicked="handleRowClick">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:cell(free)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(budget)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(enterprise)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>
    <br>
    <div class="group-label">Data Import and Export</div>
    <b-table hover :fields="tableFields" :items="dataImport" @row-clicked="handleRowClick">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:cell(free)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(budget)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(enterprise)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>
    <br>
    <div class="group-label">Support</div>
    <b-table hover :fields="tableFields" class="support-table" :items="support" @row-clicked="handleRowClick">
      <template v-slot:head(free)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(budget)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:head(enterprise)="data">
        <div class="header">
          <div class="label">{{ data.label }}</div>
        </div>
      </template>
      <template v-slot:cell(free)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(budget)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(enterprise)="data">
        <div v-if="data.value === 'P'">
          <i class="far fa-check fa-lg" style="color: #2a76f8;"></i>
        </div>
        <div v-else-if="data.value === '0'">
          <i class="far fa-times fa-lg" style="color: #f82a2a;"></i>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>
    <b-modal id="pricing-details-modal" class="pricing-modal" :title="modalTitle" hide-footer hide-backdrop centered size="lg">
      <p>{{ modalText }}</p>
      <img :src="modalImageLink" class="image-container">
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'PricingOne',
  data() {
    return {
      plans: [],
      dataset: [
        { feature: 'Report Year Range', free: '2019 to Present', budget: 'All', enterprise: 'All', modalContent: {
          text: "These are the specified periods for which you can retrieve reports based on each plan. Typically, mineral exploration reports span from the early 1900s to the present day. With the freemium plan, you'll have access to reports from the most recent 5 years while the paid plan grants access to all the years.",
          imageLink: ''
        } },
        { feature: 'Regions', free: 'One', budget: 'One', enterprise: 'Multiple', modalContent: {
          text: "Every region is a document database associated with a region.",
          imageLink: ''
        } }
      ],
      radixSearch: [
        { feature: 'Normal Search Credits', free: '10 / day', budget: '200 / 3 months', enterprise: 'Unlimited', modalContent: {
          text: "This module represents the Hybrid Extractive Search functionality of the platform and serves as the fundamental search option. A search credit will be deducted upon clicking either the 'Search' button or the 'Generate Heatmap' button.",
          imageLink: ''
        } },
        { feature: 'Nerd Search Credits', free: '10 to try', budget: '0', enterprise: 'Unlimited', modalContent: {
          text: "This module enables you to effortlessly search for intricate concepts, such as geological analogues, using straightforward syntax.",
          imageLink: ''
        } },
        { feature: 'Assay Finder Credits', free: '10 to try', budget: '0', enterprise: 'Unlimited', modalContent: {
          text: "This module allows you to search for quantified measurements like assays within documents and images.",
          imageLink: ''
        } },
        { feature: 'Fuzzy Search', free: 'P', budget: '0', enterprise: 'P', modalContent: {
          text: "This module enables you to search through documents containing misspelled words, which are frequently encountered in handwritten documents.",
          imageLink: ''
        } }
      ],
      radixEye: [
        { feature: 'Category Search Credits', free: '', budget: '', enterprise: '', modalContent: {
          text: "Explore our extensive library of geoscience images and tables, categorized into meaningful classes like maps, grids, core and more. Filter and search using a combination of classes and advanced text search modules (Normal Search, Nerd Search, and Assay Finder). Visualize results on a map to discover images within a specific geospatial area.",
          imageLink: require('../assets/img/pricing-plan/RadixEye_CategorySearch.webp')
        } },
        { feature: 'Similar Image Search Credits', free: '10 / day', budget: '0', enterprise: 'Unlimited', modalContent: {
          text: "Find similar images using our AI-powered search. Upload an image or select one from our database, and our model will retrieve similar images. View results on a heatmap to approximate their geospatial locations.",
          imageLink: require('../assets/img/pricing-plan/RadixEye_ImageSearch.webp')
        } },
        { feature: 'Description Search Credits', free: '', budget: '', enterprise: '', modalContent: {
          text: "Unleash the power of our multimodal GeoAI models to search for any geoscience concept or idea. Our models will retrieve relevant images and tables, even if they don't fit traditional categories. Get creative and search like a geologist!",
          imageLink: require('../assets/img/pricing-plan/RadixEyeGPT.webp')
        } }
      ],
      radixGpt: [
        { feature: 'RadixGPT Search', free: '10 / day', budget: '0', enterprise: 'Unlimited', modalContent: {
          text: "Interrogate your datasets like a geo expert with our GeoScience LLM, powered by RadixGPT. Ask questions, and our AI will respond with intelligent answers, providing source documents and geospatial locations on a heatmap.",
          imageLink: require('../assets/img/pricing-plan/RadixGPT.webp')
        } }
      ],
      results: [
        { feature: 'Search Results', free: 'Top 10', budget: 'Top 10', enterprise: 'All', modalContent: {
          text: "This represents the overall count of results shown following the initiation of a search. The top 10 outcomes are arranged according to a relevance score, although the sorting sequence can also be determined by the document date.",
          imageLink: ''
        } },
        { feature: 'Heatmap Search', free: 'P', budget: 'P', enterprise: 'P', modalContent: {
          text: "This module facilitates the visualization of search results in a spatial context through mapping. The heatmap depicts the distribution of the search query across documents, plotted on the map. Areas with higher heatmap intensity indicate greater concentrations of results linked to that specific spatial location.",
          imageLink: require('../assets/img/pricing-plan/Heatmap.webp')
        } },
        { feature: 'RadixSuggest', free: 'P', budget: 'P', enterprise: 'P', modalContent: {
          text: "This module empowers you to exhibit the most pertinent terms associated with a given word. This functionality aids in uncovering novel connections between terms.",
          imageLink: ''
        } },
        { feature: 'Location Filters', free: 'P', budget: 'P', enterprise: 'P', modalContent: {
          text: "This module enables you to use a polygon tool for refining your search results. You can draw an area of interest on the map, and the results will be filtered based on that specific area.",
          imageLink: require('../assets/img/pricing-plan/Location.webp')
        } },
        { feature: 'Metadata Filters', free: 'P', budget: 'P', enterprise: 'P', modalContent: {
          text: "Reports come with extra details like the year, author, operator, and more. This feature lets you sieve your search using any of these details. For instance, you can dig up reports authored only by 'Rocky Miner'.",
          imageLink: require('../assets/img/pricing-plan/Metadata.webp')
        } }
      ],
      dataImport: [
        { feature: 'RadixExtract Credits', free: '2 / week', budget: '40 / 3 months', enterprise: '1000 / year', modalContent: {
          text: "This module facilitates the seamless extraction of tables from documents. Through RadixExtract's user-friendly interface, users can simply paste an image of a table. The digitized table is then presented on the screen, with low-confidence values highlighted using a heatmap. This feature allows users to rectify the table immediately before exporting it to a CSV file or copying it to the clipboard.",
          imageLink: ''
        } },
        { feature: 'Private Data Integration', free: '0', budget: '0', enterprise: 'P', modalContent: {
          text: "Make your internal proprietary data searchable using the RadiXplore platform.",
          imageLink: ''
        } },
        { feature: 'Import Shapefiles', free: 'P', budget: 'P', enterprise: 'P', modalContent: {
          text: "Display your own shape files onto the RadiXplore map.",
          imageLink: require('../assets/img/pricing-plan/Shapefiles.webp')
        } },
        { feature: 'External Map Layers', free: 'P', budget: 'P', enterprise: 'P', modalContent: {
          text: "Connect to preconfigured external WMS layers and display them on the RadiXplore map.",
          imageLink: require('../assets/img/pricing-plan/ExternalMap.webp')
        } },
        { feature: 'Export Results', free: '0', budget: '0', enterprise: 'P', modalContent: {
          text: "Both search and heatmap results can be exported as WKT (Well-Known Text) files, which can then be seamlessly integrated into GIS systems or other compatible software.",
          imageLink: ''
        } }
      ],
      support: [
        { feature: 'Knowledge Base', free: 'P', budget: 'P', enterprise: 'P'},
        { feature: 'In-House Training', free: '0', budget: '0', enterprise: 'P', modalContent: {
          text: "This training approach is designed and delivered by internal experts or designated trainers, utilizing the organization's resources and facilities. In-house training offers the advantage of being customized to the organization's unique goals and challenges, fostering a focused learning environment while promoting team cohesion and alignment with company objectives.",
          imageLink: ''
        } },
      ],
      tableFields: ['feature', 'free', 'budget', 'enterprise'],
      planFields: ['.', 'free', 'budget', 'enterprise'],
      modalTitle: '',
      modalText: '',
      modalImageLink: ''
    };
  },
  methods: {
    setModalContent (title, modalContent) {
      this.modalTitle = title;
      this.modalText = modalContent.text;
      this.modalImageLink = modalContent.imageLink;
    },
    handleRowClick (item, index, event) {
      if (item.modalContent) {
        this.setModalContent(item.feature, item.modalContent);
        this.$bvModal.show('pricing-details-modal');
      }
    },
    handleRadixEyeClick() {
      this.setModalContent('RadixEye', {
        text: "RadixEye's advanced GeoAI technology has unlocked the wealth of information hidden in reports, extracting and analyzing images and tables. Our models have intelligently categorized these images into logical groups such as maps, grids, and geophysics, and even geotagged them for precise location reference. With RadixEye, you can now search, filter, and unlock valuable insights from this visual data with unprecedented ease and accuracy.",
        imageLink: ''
      });
      this.$bvModal.show('pricing-details-modal');
    }
  }
};
</script>
<style scoped>
.header .label {
  font-size: 1rem;
  font-weight: 500;
}
.group-label {
  font-size: large;
  color: #1b1b1bc1;
  margin-left: .5rem;
}
.header .price {
  font-size:medium;
  color: #919093;
  font-weight: 500;
  margin-bottom: 10px;
}
.ht-btn-default {
  border-radius: 8px;
  max-width: max-content;
  padding: 0px 15px !important;
}
.plan-table{
  border-top: hidden;
}
.image-container {
  width: 100%;
  max-height: 70vh;
  height: auto;
}
.radixeye-label {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>