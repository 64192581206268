import DEV from "./devEnvs.json";
import PROD from "./prodEnvs.json";

const envs = process.env.NODE_ENV === "production" ? PROD : DEV;

export default {
  VUE_APP_GA_MEASUREMENT_ID: envs.APP_GA_MEASUREMENT_ID,
  VUE_APP_RX_API_URL: envs.APP_RX_API_URL,
  VUE_APP_RX_API_KEY: envs.APP_RX_API_KEY,
  VUE_APP_AWS_REGION: envs.APP_AWS_REGION,
  VUE_APP_IDENTITY_POOL_ID: envs.APP_IDENTITY_POOL_ID,
  VUE_APP_USER_POOL_ID: envs.APP_USER_POOL_ID,
  VUE_APP_USER_POOL_WEB_CLIENT_ID: envs.APP_USER_POOL_WEB_CLIENT_ID,
  VUE_APP_ACCESS_KEY_ID: envs.APP_ACCESS_KEY_ID,
  VUE_APP_SECRET_ACCESS_KEY: envs.APP_SECRET_ACCESS_KEY,
  NODE_ENV: envs,
};
