<template>
    <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10 video-popup video-link">
            <div>
                <div class="single-popup-wrap" @click="$bvModal.show('video-modal')">
                    <div class="image-overlay"></div>
                    <img class="img-fluid" src="../assets/img/other/app-screenshot.png" alt="Image">
                    <div class="ht-popup-video video-button video-overlay">
                        <div class="video-button__one">
                            <div class="video-play">
                                <span class="video-play-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="video-modal" size="lg" centered hide-footer>
                <iframe 
                    id="video-player"
                    src="https://www.youtube.com/embed/M8nRhmnSZLc?autoplay=1" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                ></iframe>
                <!-- <iframe
                    id="video-player"
                    frameBorder="0"
                    src="https://www.youtube.com/embed/v=M8nRhmnSZLc?autoplay=1">
                </iframe> -->
            </b-modal>
            <!-- <silentbox-single src="https://www.youtube.com/watch?v=M8nRhmnSZLc">
                <div class="single-popup-wrap">
                    <div class="image-overlay"></div>
                    <img class="img-fluid" src="../assets/img/other/app-screenshot.png" alt="Image">
                    <div class="ht-popup-video video-button video-overlay">
                        <div class="video-button__one">
                            <div class="video-play">
                                <span class="video-play-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </silentbox-single> -->
        </div>
        <div class="col-lg-1"></div>
        <!-- <div class="col-lg-6 video-popup video-link mt-30">
            <silentbox-single src="https://www.youtube.com/watch?v=9No-FiEInLA">
                <div class="single-popup-wrap">
                    <img class="img-fluid" src="../assets/img/bg/testimonial-bg-570x420.jpg" alt="bg/testimonial-bg-570x420">
                    <div class="ht-popup-video video-button video-overlay">
                        <div class="video-button__two">
                            <div class="video-play">
                                <span class="video-play-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </silentbox-single>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: 'PopUpVideoOne',
    };
</script>

<style scoped>
.single-popup-wrap {
    /* border: 15px solid rgb(77, 77, 77); */
    border: 15px solid #151136;
    border-radius: 10px;
    /* width: 600px;
    height: 300px; */
    /* background: url('../assets/img/logo/logo-with-name-coloured.png') 30px 60px no-repeat; */
}

.single-popup-wrap:hover {
    cursor: pointer;
}

#video-player {
    width: 100%;
    height: 50vh;
    border-radius: 5px;
}

/* .video-play {
    background-color: rgba(0,0,0,0.6);
    z-index: 3;
}

.video-play-icon {
    color: black;
} */
</style>